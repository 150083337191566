import assert from "assert";
import { Params, YearStocks, TaxBracket } from "drawdown-web";
import { object, number as rawNumber, array, Schema } from "yup";

function number() {
  return rawNumber().typeError("A number is required");
}

function nnNumber() {
  return number().min(0, "Must not be negative");
}

const YearStocksSchema = object({
  NormalDeposits: nnNumber().required(),
  NormalGains: nnNumber().required(),
  RothContrib: nnNumber().required(),
  RothUnvested: nnNumber().required(),
  RothEarn: nnNumber().required(),
  Trad: nnNumber().required(),
});

const TaxBracketSchema = object({
  start: number().required(),
  marginal_rate: number().required(),
});

type SpendingRow = {
  total_spending: number;
  ira_eligible_spending: number;
};

export type ParamsFromInput = {
  n_adults: number;
  child_births: number[];
  start_age: number;

  initial_stocks: YearStocks;
  spending_table: SpendingRow[];

  interest_rate: number;

  fpl_table: number[];

  standard_deduction: number;
  minor_child_tax_credit_nonrefundible: number;
  adult_child_tax_credit_nonrefundible: number;
  tax_brackets: TaxBracket[];
};

function parseCurency(_: unknown, o: number | string) {
  if (typeof o == "number") {
    return o;
  }
  return Number(o.replace(/,|\$/g, ""));
}

const SpendingCSVRowSchema = object({
  total_spending: number().required().transform(parseCurency),
  ira_eligible_spending: number().default(0).transform(parseCurency),
});

export const SpendingCSVSchema = array().of(SpendingCSVRowSchema);

export const ParamsSchema: Schema<ParamsFromInput> = object({
  n_adults: number().integer().required(),
  child_births: array().of(number().integer().required()).required(),
  start_age: number().integer().required(),

  initial_stocks: YearStocksSchema.required(),
  spending_table: SpendingCSVSchema.required(),

  interest_rate: number().required(),

  fpl_table: array().of(number().required()).required(),

  standard_deduction: number().required(),
  minor_child_tax_credit_nonrefundible: number().required(),
  adult_child_tax_credit_nonrefundible: number().required(),
  tax_brackets: array().of(TaxBracketSchema).required(),
});

export function inputToSimParams(inputForm: ParamsFromInput): Params {
  const { spending_table } = inputForm;
  const spending = spending_table.map((row) => row.total_spending);
  const ira_eligible_spending = spending_table.map(
    (row) => row.ira_eligible_spending
  );
  const n_years = spending_table.length;
  return { ...inputForm, n_years, spending, ira_eligible_spending };
}

export function simToInputParams(input: Params): ParamsFromInput {
  const { n_years, spending, ira_eligible_spending, ...rest } = input;
  if (n_years != spending.length) {
    throw "spending length must be n_years";
  }
  if (n_years != ira_eligible_spending.length) {
    throw "ira eligible spending length must be n_years";
  }
  const spending_table = spending.map((total_spending, i) => {
    return {
      total_spending,
      ira_eligible_spending: ira_eligible_spending[i],
    };
  });
  return { ...rest, spending_table };
}
