import { Params } from "drawdown-web";
import { Formik, FieldArray, useField, FieldAttributes } from "formik";
import {
  CloseCircleFilled,
  DeleteOutlined,
  DeleteRowOutlined,
  InsertRowAboveOutlined,
  InsertRowBelowOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Radio, RadioChangeEvent } from "antd";
import { ChangeEvent, useState } from "react";
import { ParamsSchema, inputToSimParams, ParamsFromInput } from "./schemas";
import Papa from "papaparse";
import Dropzone from "react-dropzone";
import { DropEvent } from "react-dropzone";

export const status_table = {
  single: {
    standard_deduction: 13_850,
    tax_brackets: [
      {
        start: 0.0,
        marginal_rate: 0.1,
      },
      {
        start: 11_000.0,
        marginal_rate: 0.12,
      },
      {
        start: 44_725.0,
        marginal_rate: 0.22,
      },
      {
        start: 95_375.0,
        marginal_rate: 0.24,
      },
      {
        start: 182_100.0,
        marginal_rate: 0.32,
      },
      {
        start: 231_250.0,
        marginal_rate: 0.35,
      },
      {
        start: 578_125.0,
        marginal_rate: 0.37,
      },
    ],
  },
  mfj: {
    standard_deduction: 27_700.0,
    tax_brackets: [
      {
        start: 0.0,
        marginal_rate: 0.1,
      },
      {
        start: 22_000.0,
        marginal_rate: 0.12,
      },
      {
        start: 89_450.0,
        marginal_rate: 0.22,
      },
      {
        start: 190_750.0,
        marginal_rate: 0.24,
      },
      {
        start: 364_200.0,
        marginal_rate: 0.32,
      },
      {
        start: 462_500.0,
        marginal_rate: 0.35,
      },
      {
        start: 693_750.0,
        marginal_rate: 0.37,
      },
    ],
  },
};

function downloadParams(params: ParamsFromInput) {
  const blob = new Blob([JSON.stringify(params, null, 2)]);
  const dlURL = URL.createObjectURL(blob);
  try {
    const a = document.createElement("a");
    a.download = "sim_params.json";
    a.href = dlURL;
    a.click();
    a.remove();
  } finally {
    URL.revokeObjectURL(dlURL);
  }
}

function FieldIcon({ error }: { error: string | undefined }) {
  if (error == null) {
    return null;
  } else {
    return <CloseCircleFilled />;
  }
}

function Field(props: FieldAttributes<any> & { label?: string }) {
  const [field, meta, helpers] = useField(props.name);
  const status = meta.error ? "error" : null;
  const suffix = <FieldIcon error={meta.error} />;
  const label = props.label ? <span>{props.label}</span> : null;
  const error = meta.error ? (
    <div className="field-error">{meta.error}</div>
  ) : null;
  return (
    <>
      <label className="input-flex">
        {label}
        <span className="input-wrapper">
          <Input {...field} {...props} suffix={suffix} status={status} />
        </span>
      </label>
      {error}
    </>
  );
}

function ChildrenHeader({ children }: { children: unknown[] }) {
  const n = children.length;
  if (n == 1) {
    return <div>1 child in household</div>;
  } else {
    return <div>{n} children in household</div>;
  }
}

const SimulationInput = (props: {
  setParams: (params: Params) => void;
  params: ParamsFromInput;
}) => {
  const [spendingCSVError, setSpendingCSVError] = useState<null | string>(null);
  return (
    <Formik
      initialValues={props.params}
      validationSchema={ParamsSchema}
      onSubmit={(values) => {
        const newParams: Params = inputToSimParams(
          ParamsSchema.validateSync(values)
        );
        props.setParams(newParams);
      }}
    >
      {(formik) => {
        const onSpendingUpload = (event: ChangeEvent<HTMLInputElement>) => {
          const { files } = event.target;
          if (files == null) {
            setSpendingCSVError("a spending file is required");
            return;
          }
          const file = files[0];
          if (file == null) {
            setSpendingCSVError("a spending file is required");
            return;
          }
          if (files.length > 1) {
            setSpendingCSVError("exactly one spending file is required");
            return;
          }
          console.log(file.name);
          if (file.type != "text/csv") {
            setSpendingCSVError("spending file must be a csv");
            return;
          }
          Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            transformHeader: (h) => {
              return h.trim().replaceAll(" ", "_");
            },
            complete: ({ data }) => {
              formik.setFieldValue("spending_table", data);
              /*
              let validated;
              try {
                validated = SpendingCSVSchema.validateSync(data);
              } catch (e) {
                if (e instanceof ValidationError) {
                  formik.setFieldError("spending_table", e.message);
                } else {
                  setSpendingCSVError("unknown parsing error");
                }
                return;
              }
              if (validated == null) {
                setSpendingCSVError("unknown parsing error (got null)");
                return;
              }
              formik.setFieldValue("spending_table", validated);
               */
            },
          });
          setSpendingCSVError(null);
        };
        const onParamsUpload = async (files: File[], event: DropEvent) => {
          if (files.length == 0) {
            return;
          }
          const file = files[0];
          if (files.length > 1) {
            /*
            formik.setFieldError(
              "spending",
              "exactly one spending file is required"
            );
             */
            return;
          }
          console.log(file.name);
          if (file.type != "application/json") {
            //formik.setFieldError("spending", "spending file must be a csv");
            return;
          }
          const newParams = JSON.parse(await file.text());
          formik.setValues(newParams, true);
        };
        const setStatus = (status: "single" | "mfj") => {
          return () => {
            formik.setValues({ ...formik.values, ...status_table[status] });
          };
        };
        const filing_status =
          formik.values.standard_deduction ==
          status_table.single.standard_deduction
            ? "single"
            : "mfj";
        return (
          <form onSubmit={formik.handleSubmit} className="SimulationInput">
            <div className="SimulationInputScrollable">
              <Field
                name="interest_rate"
                type="text"
                label="Real Interest Rate"
              />

              <fieldset className="InitialStocksInput">
                <legend>Finances at the start of the simulation</legend>
                <div>
                  Standard accounts
                  <Field
                    label="Cost Basis"
                    name="initial_stocks.NormalDeposits"
                    type="text"
                  />
                  <Field
                    name="initial_stocks.NormalGains"
                    type="text"
                    label="Unrealized Capital Gains"
                  />
                  IRA / 401(k)
                  <Field
                    name="initial_stocks.RothContrib"
                    type="text"
                    label="Roth Contributions"
                  />
                  {/*
                Disabled because it'll never vest. Need a better way to do this.
                <label>
                  Unvested Roth Conversions
                  <Field name="initial_stocks.RothUnvested" type="text" />
                </label>
                */}
                  <Field
                    name="initial_stocks.RothEarn"
                    type="text"
                    label="Roth Earnings"
                  />
                  <Field
                    name="initial_stocks.Trad"
                    type="text"
                    label="Traditional IRA/401(k)"
                  />
                </div>
              </fieldset>
              <fieldset>
                <legend>Household</legend>
                <div>
                  <div>
                    <Field
                      name="start_age"
                      type="text"
                      label="Age at start of simulation"
                    />
                  </div>
                  <div>
                    <Field
                      name="n_adults"
                      type="text"
                      label="Number of adults in the household"
                    />
                  </div>
                  <Radio.Group value={filing_status}>
                    <Radio value="single" onClick={setStatus("single")}>
                      Single
                    </Radio>
                    <Radio value="mfj" onClick={setStatus("mfj")}>
                      Married Filing Jointly
                    </Radio>
                  </Radio.Group>

                  <ChildrenHeader children={formik.values.child_births} />
                  <FieldArray name="child_births">
                    {({ insert, remove, push }) => (
                      <div>
                        {formik.values.child_births.length > 0 &&
                          formik.values.child_births.map((child, index) => (
                            <div key={index}>
                              <div className="childRow">
                                <Field
                                  label={`Child ${index + 1} Birth Year`}
                                  name={`child_births.${index}`}
                                  placeholder="0"
                                  type="text"
                                />
                                <Button
                                  type="default"
                                  onClick={() => remove(index)}
                                >
                                  <DeleteOutlined />
                                </Button>
                              </div>
                            </div>
                          ))}
                        <Button type="default" onClick={() => push("0")}>
                          Add Child
                        </Button>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </fieldset>

              <fieldset>
                <legend>Spending</legend>
                <div>
                  <label>
                    Upload CSV:
                    <input
                      type="file"
                      onChange={onSpendingUpload}
                      accept=".csv,text/csv"
                    />
                  </label>
                  {spendingCSVError}
                  <FieldArray name="spending_table">
                    {({ insert, remove, push }) => (
                      <div>
                        <table>
                          <tr>
                            <th>Year</th>
                            <th>Total Spending</th>
                            <th>IRA Eligible Spending</th>
                          </tr>
                          {formik.values.spending_table.length > 0 &&
                            formik.values.spending_table.map((year, index) => (
                              <tr key={index}>
                                <td className="yearCell">{index + 1}</td>
                                <td className="formCell">
                                  <Field
                                    name={`spending_table.${index}.total_spending`}
                                    placeholder="0"
                                    type="text"
                                  />
                                </td>
                                <td className="formCell">
                                  <Field
                                    name={`spending_table.${index}.ira_eligible_spending`}
                                    placeholder="0"
                                    type="text"
                                  />
                                </td>
                                <td className="formCell">
                                  <Button
                                    type="default"
                                    title="Delete row"
                                    onClick={() => remove(index)}
                                  >
                                    <DeleteOutlined />
                                  </Button>
                                </td>
                                <td className="formCell">
                                  <Button
                                    type="default"
                                    title="Insert row above"
                                    onClick={() =>
                                      insert(index, {
                                        total_spending: 0,
                                        ira_eligible_spending: 0,
                                      })
                                    }
                                  >
                                    <InsertRowAboveOutlined />
                                  </Button>
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td className="formCell"></td>
                            <td className="formCell"></td>
                            <td className="formCell"></td>
                            <td className="formCell"></td>
                            <td className="formCell">
                              <Button
                                type="default"
                                title="Insert row at the bottom"
                                onClick={() =>
                                  push({
                                    total_spending: 0,
                                    ira_eligible_spending: 0,
                                  })
                                }
                              >
                                <InsertRowBelowOutlined />
                              </Button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    )}
                  </FieldArray>
                </div>
              </fieldset>
            </div>

            <Button type="primary" htmlType="submit">
              Optimize
            </Button>

            <Button
              type="default"
              onClick={() => downloadParams(formik.values)}
            >
              Download Sim Params
            </Button>
            <Dropzone
              accept={{ "application/json": [".json"] }}
              onDropAccepted={onParamsUpload}
            >
              {({ getRootProps, getInputProps }) => (
                <Button {...getRootProps()}>
                  <input {...getInputProps()} />
                  Upload Sim Params
                </Button>
              )}
            </Dropzone>
          </form>
        );
      }}
    </Formik>
  );
};

export default SimulationInput;
