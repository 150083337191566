import { useState } from "react";
import { Params } from "drawdown-web";
import Simulation from "./Simulation";
import SimulationInput, { status_table } from "./Input";
import "./App.css";
import { simToInputParams } from "./schemas";

function App() {
  const [params, setParams] = useState<Params>({
    n_years: 2,
    n_adults: 2,
    child_births: [],
    start_age: 30,
    initial_stocks: {
      NormalDeposits: 0,
      NormalGains: 110000,
      RothContrib: 0,
      RothUnvested: 0,
      RothEarn: 0,
      Trad: 0,
    },
    spending: [50000, 60000],
    ira_eligible_spending: [0, 0],
    interest_rate: 0.04,
    fpl_table: [
      13590.0, 18310.0, 23030.0, 27750.0, 32470.0, 37190.0, 41910.0, 46630.0,
    ],
    minor_child_tax_credit_nonrefundible: 600.0,
    adult_child_tax_credit_nonrefundible: 500.0,
    ...status_table.mfj,
  });
  return (
    <div className="App">
      <SimulationInput
        setParams={setParams}
        params={simToInputParams(params)}
      />
      <Simulation {...params} />
    </div>
  );
}

export default App;
